import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { doc } from "firebase/firestore";
import { useEffect } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { converters } from "~/lib/converter";
import { useLoggedInState } from "~/lib/useLoggedInState";

/**
 * Check for the existence of /networkAdmins/{uid}
 * If it exists and is active, set isNetworkAdmin to true
 *
 * Firestore rules also validate this during reads.
 */
export function NetworkAdminState() {
  const { user, network, setIsNetworkAdmin } = useLoggedInState();
  const firestore = useFirestore();

  const { status, data: networkAdmin } = useFirestoreDocData(
    (user && network
      ? doc(network.ref, "networkAdmins", user.uid)
      : doc(firestore, "noop/noop")
    ).withConverter(converters.networkAdmin.read)
  );

  useEffect(
    function updateIsNetworkAdmin() {
      if (status !== "loading") {
        const isNA = Boolean(
          networkAdmin &&
            networkAdmin.latestDocUpdate.status === DocStatuses.Active
        );

        setIsNetworkAdmin(isNA);

        console.log("NetworkAdminState", {
          isNA,
          status,
          user,
          network,
          networkAdmin,
        });
      }
    },
    [status, networkAdmin]
  );

  // no UI - just hooks and state management
  return <></>;
}
