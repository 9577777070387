import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import React from "react";
import { Button, styled } from "@mui/material";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { useAuth } from "reactfire";

const SocialButton = styled(Button)({
  // boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  borderWidth: 1,
});

const AppleButton = styled(SocialButton)({
  // boxShadow: "none",
  textTransform: "none",
  backgroundColor: "#000",
  borderWidth: 0,
  color: "white",
  "&:hover": {
    backgroundColor: "#000",
    // boxShadow: "none",
  },
  "&:active": {
    // boxShadow: "none",
    backgroundColor: "#333",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const GoogleButton = styled(SocialButton)({
  color: "#444",
  backgroundColor: "#fff",
  borderColor: "#333",
  "&:hover": {
    backgroundColor: "#fafafa",
    // boxShadow: "none",
  },
  "&:active": {
    // boxShadow: "none",
    backgroundColor: "#ddd",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function SocialSignIn({
  label = "Sign in",
}: {
  label?: string;
}): React.ReactElement {
  const auth = useAuth();

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    // googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("profile");
    provider.addScope("email");
    // use this for localhost:
    // signInWithPopup(auth, provider);
    // use this for production (and deployed dev):
    signInWithRedirect(auth, provider);
    // the getRedirectResult and onAuthStateChanged handlers  will pick up the
    // flow after redirect (assuming the user gets logged in).
    // We should test out error flows and figure out how to handle them as well.
    // This just assumes it was a success.
  };

  const handleAppleSignIn = () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    signInWithRedirect(auth, provider);
  };

  return (
    <>
      <GoogleButton
        sx={{ width: "100%" }}
        onClick={handleGoogleSignIn}
        variant="contained"
        startIcon={<GoogleIcon sx={{ color: "#444" }} />}
      >
        {label} with Google
      </GoogleButton>
      <AppleButton
        sx={{ width: "100%", mt: 2 }}
        onClick={handleAppleSignIn}
        variant="contained"
        startIcon={<AppleIcon sx={{ color: "#fff" }} />}
      >
        {label} with Apple
      </AppleButton>
    </>
  );
}
