import { converters } from "~/lib/converter";
import { WithRef } from "@converge-collective/common/models/Base";
import {
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";
import {
  Network,
  NetworkMembership,
} from "@converge-collective/common/models/Network";
import {
  NanoProfile,
  Profile,
  liteProfile,
  nanoProfile,
} from "@converge-collective/common/models/Profile";
import { updateDoc, getDoc, doc, setDoc } from "@firebase/firestore";

export const addNetworkAdmin = async (
  network: WithRef<Network>,
  actor: WithRef<Profile>,
  profileToMakeAdmin: NanoProfile
) => {
  const networkAdminDoc = doc(
    network.ref,
    "networkAdmins",
    profileToMakeAdmin.id
  ).withConverter(converters.networkAdmin.write);
  const existingDoc = await getDoc(networkAdminDoc);
  const description = `${actor.name} granted Network Admin to ${network.name} [${network.id}]`;
  const update: DocUpdate = {
    date: new Date(),
    status: DocStatuses.Active,
    description,
    actor: nanoProfile(actor),
  };

  if (existingDoc.exists()) {
    await updateDoc(networkAdminDoc, {
      ...nanoProfile(profileToMakeAdmin),
      latestDocUpdate: update,
    });
  } else {
    // create new network admin doc
    await setDoc(
      networkAdminDoc,
      {
        ...nanoProfile(profileToMakeAdmin),
        latestDocUpdate: update,
      },
      { merge: true }
    );
  }
};

export const joinNetwork = async (
  network: WithRef<Network>,
  profile: WithRef<Profile>
) => {
  const membershipRef = doc(network.ref, "members", profile.id);
  // join the network
  const membership: NetworkMembership = {
    network,
    createdAt: new Date(),
    // we use lite profile specifically here because we want more details on the
    // network membership, so we can show them in search results for example
    profile: liteProfile(profile),
    profileRef: doc(network.ref.firestore, "profiles", profile.id),
    role: "member",
    team: null,
  };
  // people can add themselves as members on `public` networks
  await setDoc(membershipRef, membership);
  // upon joining, set it as the current network membership
  console.log("join network setting currentNetworkMembership", { membership });
  updateDoc(profile.ref, { currentNetworkMembership: membership });
};
