import { Stack, SxProps } from "@mui/material";
import Link from "next/link";
import React from "react";
import { useSigninCheck } from "reactfire";
import { useLoggedInState } from "~/lib/useLoggedInState";
import convergeLogo from "~/public/static/img/converge_logo_final.svg";

const LogoHref = "/";
const DefaultLogoHeight = 76;
const DefaultLogoWidth = 80;

type BrandLogoProps = {
  sx?: SxProps;
  width?: number;
  height?: number;
  href?: string;
};

const BrandLogo = ({
  sx,
  height,
  width,
  href = LogoHref,
}: BrandLogoProps): React.ReactElement => {
  const { status } = useSigninCheck();
  const { network } = useLoggedInState();

  const isAuthLoading = status === "loading";

  const { isWhiteLabelingEnabled } = network?.settings || {};
  const whiteLabelLogo = network?.settings?.whiteLabelInfo?.logoUrl;
  const logoUrl = isAuthLoading
    ? convergeLogo
    : isWhiteLabelingEnabled && whiteLabelLogo
      ? whiteLabelLogo
      : convergeLogo;

  const companyName =
    network && isWhiteLabelingEnabled ? network.name : "Converge";

  return (
    <Stack sx={sx} justifyContent="center" direction="row" spacing={2}>
      <Link href={href} title={companyName}>
        <img
          alt={`${companyName} Logo`}
          src={logoUrl}
          style={{
            width: "100%",
            maxHeight: height || DefaultLogoHeight,
            maxWidth: width || DefaultLogoWidth,
          }}
        />
      </Link>
    </Stack>
  );
};

export default BrandLogo;
