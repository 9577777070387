import { Profile } from "@converge-collective/common/models/Profile";
import { DocumentReference, updateDoc } from "firebase/firestore";
import * as amplitude from "@amplitude/analytics-browser";
import { amplitudeEvents } from "~/lib/amplitude";

/**
 * Centraliezd profile updater so we can also track amplitude events
 */
export async function updateProfile(
  ref: DocumentReference,
  p: Partial<Profile>
) {
  console.log("updating profile", { profile: p });
  await updateDoc(ref, p);
  amplitude.track(amplitudeEvents.profileUpdated, {
    uid: ref.id,
    updateData: p,
  });
}
