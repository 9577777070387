import { WithRef } from "@converge-collective/common/models/Base";
import {
  ChallengeKinds,
  ChallengeSection,
  GoalChallenge,
} from "@converge-collective/common/models/Challenge";
import {
  BaseChallengeV2,
  ChallengeBlock,
  ChallengeV2,
  CourseChallenge,
  GoalChallengeV2,
  GoalChallengeV2Template,
} from "@converge-collective/common/models/ChallengeV2";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { Network } from "@converge-collective/common/models/Network";
import {
  LiteProfile,
  Profile,
} from "@converge-collective/common/models/Profile";
import { Tag } from "@converge-collective/common/models/Tag";
import { shallowTSToDates } from "@converge-collective/common/util";
import { addMonths } from "date-fns";
import {
  collection,
  collectionGroup,
  doc,
  orderBy as firestoreOrderBy,
  getCountFromServer,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { isEmpty, orderBy, partition } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import useSWR from "swr";
import { converters } from "~/lib/converter";
import { useCourseEditMode } from "~/lib/useCourseEditMode";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { useMyGroups } from "./useMyGroups";

export function useAssignedChallenges(
  network: WithRef<Network>,
  profile: WithRef<LiteProfile>
): {
  assignedChallenges: WithRef<ChallengeV2>[];
} {
  // const {groups} = useMyGroups();
  // const groupIds = groups.map(g => g.id);

  const ref = query(
    collection(network.ref, "challengesV2").withConverter(
      converters.goalChallengeV2.read
    ),
    where("kind", "in", [
      ChallengeKinds.GoalChallenge,
      ChallengeKinds.CourseChallenge,
    ]),
    where("assigneeIds", "array-contains", profile.id),
    // ...(groupIds && groupIds.length ? [where("assignedGroupIds", "in", groupIds)] : []),
    // This query results in following error : FirebaseError: Too many disjunctions after normalization. Result had 58 disjunctions which is more than the maximum of 30
    // Probably because of the groupIds array being too long and "in" operator being used
    where("latestDocUpdate.status", "==", DocStatuses.Published)
  );
  const { data: assignedChallenges = [] } = useFirestoreCollectionData(ref);
  return {
    assignedChallenges: orderBy(
      assignedChallenges.map(shallowTSToDates),
      (c) => c.createdAt,
      "desc"
    ),
  };
}

// A template can be the basis for a challenge or course.
export function useChallengeTemplates(network: WithRef<Network>): {
  templates: WithRef<GoalChallengeV2Template>[];
} {
  const templatesRef = query(
    collection(network.ref, "challengeV2Templates").withConverter(
      converters.goalChallengeV2Template.read
    ),
    where("kind", "==", ChallengeKinds.GoalChallenge)
  );

  const { data: templateData = [] } = useFirestoreCollectionData(templatesRef);

  return {
    templates: orderBy(
      templateData.map(shallowTSToDates),
      (c) => c.createdAt,
      "desc"
    ),
  };
}

export function useCourseChallenges(tags?: string[]) {
  const { network } = useLoggedInState();
  const { isNetworkAdmin } = useLoggedInState();
  const firestore = useFirestore();
  const ref = query(
    (network
      ? collection(network.ref, "challengesV2")
      : collection(firestore, "noop")
    ).withConverter(converters.courseChallenge.read),
    where("kind", "==", ChallengeKinds.CourseChallenge),
    where("latestDocUpdate.status", "!=", DocStatuses.Deleted),
    ...(isNetworkAdmin
      ? []
      : [where("latestDocUpdate.status", "==", DocStatuses.Published)]),

    ...(tags && tags.length > 0
      ? [where("tagIds", "array-contains-any", tags)]
      : []),
    firestoreOrderBy("createdAt", "desc")
  );

  const results = useFirestoreCollectionData(ref);
  const courseChallenges = results.data || [];
  // console.log("useCourseChallenges", { network, tagIds, courseChallenges });

  return {
    courseChallenges,
    ...results,
  };
}

export function useCourseChallenge(slug?: string): {
  course: WithRef<CourseChallenge> | undefined;
  isLoading: boolean;
} {
  const { network } = useLoggedInState();
  const firestore = useFirestore();
  const ref = (
    slug && network?.ref
      ? collection(network.ref, "challengesV2")
      : collection(firestore, "noop")
  ).withConverter(converters.courseChallenge.read);
  const queryRef = query(ref, where("slug", "==", slug));
  const { data: courseChallenge, status } =
    useFirestoreCollectionData(queryRef);
  if (!slug) {
    return { course: undefined, isLoading: false };
  }

  const course = !isEmpty(courseChallenge) ? courseChallenge[0] : undefined;
  return { course, isLoading: status === "loading" };
}

// TODO - add optional params to query
// - challenges for specific user
// - challenges for specific group or team (visibleByIds)
export function useGoalChallengesV2(network: WithRef<Network>): {
  pastChallenges: WithRef<GoalChallengeV2>[];
  currentChallenges: WithRef<GoalChallengeV2>[];
} {
  const [now] = useState(new Date());
  // only query future challenges and challenges that ended in the last 2 months
  const minStartAt = addMonths(now, -4);
  const challengesRef = query(
    collection(network.ref, "challengesV2").withConverter(
      converters.goalChallengeV2.read
    ),
    where("startAt", ">", minStartAt),
    where("kind", "==", ChallengeKinds.GoalChallenge)
  );
  const { data: challengeData = [] } =
    useFirestoreCollectionData(challengesRef);

  const challenges = orderBy(
    challengeData.map(shallowTSToDates),
    (c) => c.startAt,
    "asc"
  );

  const [pastChallenges, currentChallenges] = (challenges &&
    partition(challenges, (c) => !c.startAt || c.startAt > now)) || [[], []];

  return { pastChallenges, currentChallenges };
}

type ChallengesReturn = {
  pastChallenges: WithRef<GoalChallenge>[];
  currentChallenges: WithRef<GoalChallenge>[];
};

export default function useChallenges(
  network: WithRef<Network>
): ChallengesReturn {
  const [now] = useState(new Date());
  const minEndAt = addMonths(now, -18);

  // Challenges Query
  const { data: challengeData } = useFirestoreCollectionData(
    query(
      collection(network.ref, "challenges").withConverter(
        converters.goalChallenge.read
      ),
      where("endAt", ">", minEndAt),
      where("kind", "==", ChallengeKinds.GoalChallenge)
      // we can't limit the number AND constrain the end date or else we'll
      // filter out new challenges
      // limit(12)
    )
  );

  // All Challenges
  const challenges = (challengeData || []).map((challenge) => {
    return {
      ...challenge,
      ...(challenge.goals
        ? { goals: challenge.goals.map(shallowTSToDates) }
        : null),
    };
  });

  // Sorted by Start Date
  const sortedChallenges = orderBy(challenges, (c) => c.startAt, "asc");

  // Partion by end date - Previous and Active Challenges
  const partitionBy = (c: WithRef<GoalChallenge>) => c.endAt < now;
  const [pastChallenges, currentChallenges] = sortedChallenges
    ? partition(sortedChallenges, partitionBy)
    : [[], []];

  return { pastChallenges, currentChallenges };
}

/** fetch blocks for any type of challenge */
export function useBlocks(challenge?: WithRef<BaseChallengeV2>): {
  blocks: WithRef<ChallengeBlock>[];
  isLoading: boolean;
} {
  const { isEditing } = useCourseEditMode();
  const firestore = useFirestore();

  const ref = (
    challenge
      ? collection(challenge.ref, "challengeBlocks")
      : collection(firestore, "noop")
  ).withConverter(converters.challengeBlock.read);
  const { data: blocks = [], status } = useFirestoreCollectionData(ref);

  if (!challenge) {
    return {
      blocks: [],
      isLoading: false,
    };
  }

  const isLoading = status === "loading";

  // if the user passed a blockOrder, sort the blocks by that order
  if (challenge?.blockOrder) {
    const blockById = blocks.reduce(
      (acc, block) => ({ ...acc, [block.slug]: block }),
      {} as Record<string, WithRef<ChallengeBlock>>
    );
    const orderedBlocks = challenge.blockOrder
      .map((slug) => blockById[slug])
      .filter((b) => !!b);
    return {
      blocks: isEditing
        ? orderedBlocks
        : orderedBlocks.filter((block) => block.sectionOrder?.length > 0),
      isLoading: status === "loading",
    };
  }

  // otherwise just return them in the order they were fetched
  return {
    blocks: blocks.map(shallowTSToDates),
    isLoading,
  };
}

export function useBlock(
  courseSlug?: string,
  blockSlug?: string
): { block: WithRef<ChallengeBlock> | undefined } {
  const firestore = useFirestore();
  const { network } = useLoggedInState();
  const blockRef = (
    network && courseSlug && blockSlug
      ? doc(
          network.ref,
          "challengesV2",
          courseSlug,
          "challengeBlocks",
          blockSlug
        )
      : doc(firestore, "noop/noop")
  ).withConverter(converters.challengeBlock.read);
  const { data: block } = useFirestoreDocData(blockRef);
  return { block };
}

/** sort sections by specified order in the parent block */
const orderSections = (
  block: WithRef<ChallengeBlock>,
  sections: WithRef<ChallengeSection>[]
): WithRef<ChallengeSection>[] => {
  if (block?.sectionOrder) {
    const sectionsBySlug = sections.reduce(
      (acc, section) => ({ ...acc, [section.slug]: section }),
      {} as Record<string, WithRef<ChallengeSection>>
    );
    const orderedSections = block.sectionOrder
      .map((slug) => sectionsBySlug[slug])
      .filter((b) => !!b);

    return orderedSections;
  }
  // no sort order specified
  return sections;
};

export const useChallengeSections = (
  block?: WithRef<ChallengeBlock>
): {
  isLoading: boolean;
  sections: WithRef<ChallengeSection>[];
} => {
  const firestore = useFirestore();

  const ref = (
    block
      ? collection(block.ref, "challengeSections")
      : collection(firestore, "noop")
  ).withConverter(converters.challengeSection.read);
  const { data: sections = [], status } = useFirestoreCollectionData(ref);

  if (!block) {
    return { sections: [], isLoading: false };
  }
  const isLoading = status === "loading";
  const orderedSections = orderSections(block, sections);
  return { sections: orderedSections, isLoading };
};

export const useAllChallengeSections = (
  challenge?: WithRef<BaseChallengeV2>
) => {
  const firestore = useFirestore();
  const { network } = useLoggedInState();
  const sectionsQuery =
    challenge && network
      ? query(
          collectionGroup(firestore, "challengeSections"),
          where("network.ref", "==", network.ref),
          where("challengeV2Id", "==", challenge.id)
        )
      : collection(firestore, "noop");
  return useFirestoreCollectionData(
    sectionsQuery.withConverter(converters.challengeSection.read)
  );
};

/** find a specific challenge section by slug */
export const useChallengeSection = (
  block?: WithRef<ChallengeBlock>,
  sectionSlug?: string
): {
  isLoading: boolean;
  section?: WithRef<ChallengeSection>;
} => {
  const firestore = useFirestore();
  const ref = (
    block && sectionSlug
      ? doc(block.ref, "challengeSections", sectionSlug)
      : doc(firestore, "noop/noop")
  ).withConverter(converters.challengeSection.read);
  const { data: section, status } = useFirestoreDocData(ref);
  const isLoading = status === "loading";
  return { section, isLoading };
};

const fetchSections = async (block: WithRef<ChallengeBlock>) => {
  const sectionsSnap = await getDocs(
    collection(block.ref, "challengeSections").withConverter(
      converters.challengeSection.read
    )
  );
  const sections = sectionsSnap.docs.map((doc) => doc.data());
  return orderSections(block, sections);
};

/** Return all activities for a challenge */
export const useChallengeActivityLogs = (
  challenge?: WithRef<BaseChallengeV2>,
  profile?: WithRef<Profile> | WithRef<LiteProfile>,
  /** optional section id to filter by */
  sectionId?: string
) => {
  const firestore = useFirestore();
  const userActivityLogsRef = (
    profile
      ? collection(profile.ref, "activityLogs")
      : collection(firestore, "noop")
  ).withConverter(converters.goalV2ActivityLog.read);
  const q = query(
    userActivityLogsRef,
    where("challengeV2Id", "==", challenge?.id || ""),
    // optionally add sectionId to the query
    ...(sectionId ? [where("sectionId", "==", sectionId)] : [])
  ).withConverter(converters.goalV2ActivityLog.read);

  return useFirestoreCollectionData(q);
};

/**
 * fetch the entire course outline so we can build a tree nav.
 *
 * This is somewhat inefficient because it fetches all the blocks and sections
 * in the future we may want to store section content as a sub-doc so it can be
 * lazily fetched on demand
 */
export const useCourseOutline = (course?: WithRef<CourseChallenge>) => {
  // TODO
  // listen to all blocks
  // listen to all sections in a group query?
  const { blocks, isLoading: isBlocksLoading } = useBlocks(course);
  const key = JSON.stringify(blocks || []);
  type BlockSections = {
    block: WithRef<ChallengeBlock>;
    sections: WithRef<ChallengeSection>[];
  };
  const [isLoading, setIsLoading] = useState(true);
  const [blockSections, setBlockSections] = useState<BlockSections[]>([]);
  // fetch all sections for each block any time the blocks array changes
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const newBlockSections: BlockSections[] = await Promise.all(
        blocks.map(async (block) => {
          const sections = await fetchSections(block);
          return { block, sections };
        })
      );
      setBlockSections(newBlockSections);
      setIsLoading(false);
    };
    if (!isEmpty(blocks)) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return {
    isLoading: isBlocksLoading || isLoading,
    // ordered nested array of blocks and sections
    blockSections,

    // produce a flattened data structure so we can lookup block and section by
    // section id
    indexedSections: Object.fromEntries(
      blockSections.flatMap(({ block, sections }) =>
        sections.map((section) => [section.slug, { block, section }])
      )
    ),
    // indexed map
    indexedBlocks: Object.fromEntries(
      blockSections.map(({ block, sections }) => [
        block.slug,
        {
          block,
          sections: Object.fromEntries(
            sections.map((section) => [section.slug, section])
          ),
        },
      ])
    ),
  };
};

/** fetch all the activity logs for a specific challenge */
export const useChallengeCompletion = (
  challenge?: WithRef<BaseChallengeV2>,
  /** pass in profile so we can ref other profiles instead of using
   * useLoggedInState */
  profile?: WithRef<Profile> | WithRef<LiteProfile>
) => {
  const { data: sectionCount = 0 } = useSectionsCount(challenge);
  const { blocks } = useBlocks(challenge);
  // we always have to fetch sections to compute completion because we need to
  // ensure the IDs line up with the activity logs. otherwise, if an admin
  // removed a section from a course (after a user completed it) the math would
  // be off.
  const { data: sections = [] } = useAllChallengeSections(challenge);
  const { data: activityLogs = [] } = useChallengeActivityLogs(
    challenge,
    profile
  );

  // Ordered Sections
  const orderedSections: WithRef<ChallengeSection>[] = blocks.flatMap((block) =>
    orderSections(block, sections)
  );

  // Logs Analysis
  const activityLogsBySectionId = Object.fromEntries(
    activityLogs.map((log) => [log.sectionId, log])
  );

  const completedSections: WithRef<ChallengeSection>[] = useMemo(() => {
    if (orderedSections.length === 0) return [];
    return orderedSections.filter(
      (section) => activityLogsBySectionId[section.id]
    );
  }, [orderedSections]);

  const incompleteSections: WithRef<ChallengeSection>[] = useMemo(() => {
    if (orderedSections.length === 0) return [];
    return orderedSections.filter(
      (section) => !activityLogsBySectionId[section.id]
    );
  }, [orderedSections]);

  const hasSections = sectionCount > 0;
  const isCompleted = hasSections && completedSections.length === sectionCount;
  const isStarted = hasSections && completedSections.length > 0;

  return {
    activityLogs,
    activityLogsBySectionId,
    sections,
    sectionCount,
    completedSections,
    incompleteSections,
    courseProgress: (completedSections.length / sectionCount) * 100,
    isCompleted,
    isStarted,
    hasSections,
  };
};

function useSectionsCount(challenge?: WithRef<BaseChallengeV2>) {
  const firestore = useFirestore();
  const { network } = useLoggedInState();

  const sectionsQuery =
    challenge && network
      ? query(
          collectionGroup(firestore, "challengeSections"),
          where("network.ref", "==", network.ref),
          where("challengeV2Id", "==", challenge.id)
        )
      : collection(firestore, "noop");

  return useSWR(
    network && challenge ? [network.id, challenge.id, "sectionCount"] : null,
    async () => {
      const c = await getCountFromServer(sectionsQuery);
      return c.data().count;
    }
  );
}

export const useChallengeProgress = (challenge: WithRef<BaseChallengeV2>) => {
  const firestore = useFirestore();
  const { network } = useLoggedInState();
  const { data: sections = [] } = useAllChallengeSections(challenge);

  const { data: userActivityLogs = [], status } = useFirestoreCollectionData(
    query(
      collectionGroup(firestore, "activityLogs"),
      where("network.id", "==", network?.id),
      where("challengeV2Id", "==", challenge?.id || "")
    ).withConverter(converters.goalV2ActivityLog.read)
  );

  const activityIds = userActivityLogs.map((log) => log.id).join(",");

  const assigneesMetrics = useMemo(() => {
    return challenge.assignees.map((assignee) => {
      // All logs of assignee
      const assigneeLogs = userActivityLogs.filter(
        (log) => log.profile.id === assignee.profile.id
      );

      // Section logs for assignee
      const activityLogsBySectionId = Object.fromEntries(
        assigneeLogs.map((log) => [log.sectionId, log])
      );

      // Completed sections of assignee
      const completedSections = sections.filter(
        (section) => activityLogsBySectionId[section.id]
      );

      // Calculate metrics
      const hasSections = sections.length > 0;
      const isCompleted =
        hasSections && completedSections.length === sections.length;
      const isStarted = hasSections && completedSections.length > 0;
      const progress = completedSections.length / sections.length || 0;

      return {
        ...assignee.profile,
        isCompleted,
        isStarted,
        sectionsDone: completedSections.length,
        progress: progress * 100,
        assignedAt: assignee.assignedAt,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge.id, activityIds, status]);

  // console.log("useChallengeProgress", {
  //   userActivityLogs,
  //   status,
  //   challenge,
  //   network,
  //   assigneesMetrics,
  //   sections,
  // });

  return { assigneesMetrics, status };
};

type ProfileChallenges = {
  courseChallenges: WithRef<
    CourseChallenge & {
      progress: number;
      startedAt: Date;
      lastUpdated: Date;
      completedAt: Date | undefined;
    }
  >[];
};
export function useUserCourses(
  profile: WithRef<Profile> | null
): ProfileChallenges {
  const firestore = useFirestore();
  const { network } = useLoggedInState();

  // User Courses
  const { data: courseChallenges = [] } = useFirestoreCollectionData(
    (network
      ? query(
          collection(network.ref, "challengesV2"),
          where("kind", "==", ChallengeKinds.CourseChallenge),
          where("latestDocUpdate.status", "!=", DocStatuses.Deleted),
          where("latestDocUpdate.status", "==", DocStatuses.Published),
          ...(profile
            ? [where("assigneeIds", "array-contains", profile.id)]
            : []),
          firestoreOrderBy("createdAt", "desc")
        )
      : collection(firestore, "noop")
    ).withConverter(converters.courseChallenge.read)
  );

  // Activity Logs
  const { data: userActivityLogs = [] } = useFirestoreCollectionData(
    query(
      collectionGroup(firestore, "activityLogs"),
      where("network.id", "==", network?.id),
      where("profile.id", "==", profile?.id || "")
    ).withConverter(converters.goalV2ActivityLog.read)
  );

  // Sections
  const { data: sections = [] } = useFirestoreCollectionData(
    (network
      ? query(
          collectionGroup(firestore, "challengeSections"),
          where("network.ref", "==", network.ref)
        )
      : collection(firestore, "noop")
    ).withConverter(converters.challengeSection.read)
  );

  const coursesWithProgress = useMemo(() => {
    return courseChallenges.map((course) => {
      const logsForCourse = userActivityLogs.filter(
        (l) => l.challengeV2Id === course.id
      );

      // Section wise logs
      const activityLogsBySectionId = Object.fromEntries(
        logsForCourse.map((log) => [log.sectionId, log])
      );

      // Course Sections
      const courseSections = sections.filter(
        (section) => section.challengeV2Id === course.id
      );

      // Completed sections of assignee
      const completedSections = courseSections.filter(
        (section) => activityLogsBySectionId[section.id]
      );

      // Timestamps
      const assignee = (course.assignees || []).find(
        (p) => p.profile.id === profile?.id
      );
      const logDates = logsForCourse
        .map((s) => s.date)
        .sort((a, b) => (a > b ? 1 : -1));
      const completedAt =
        courseSections.length === completedSections.length
          ? logDates[logDates.length - 1]
          : undefined;
      const assigneeStartDate = assignee ? assignee.assignedAt : logDates[0];

      return {
        ...course,
        progress: +(
          (completedSections.length / courseSections.length) * 100 || 0
        ).toFixed(2),
        startedAt: assigneeStartDate,
        lastUpdated: logDates[logDates.length - 1],
        completedAt: completedAt,
      };
    });
  }, [courseChallenges, userActivityLogs, sections]);

  return {
    courseChallenges: coursesWithProgress,
  };
}
