import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { CreateAccount } from "./CreateAccount";

export default function CreateAccountDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}): React.ReactElement {
  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="md" onClose={handleClose}>
      <DialogTitle>Let's get started.</DialogTitle>
      <CreateAccount
        handleClose={handleClose}
        WrapperContent={DialogContent}
        WrapperActions={DialogActions}
      />
    </Dialog>
  );
}
