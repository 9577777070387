import School from "@mui/icons-material/School";
import { isEmpty } from "lodash";
import Link from "next/link";
import { MenuItem, SubMenu, menuClasses } from "react-pro-sidebar";
import { coursesRoute } from "~/routes";
import { useAssignedChallenges } from "~/hooks/useChallenges";
import { CourseMenuItem } from "./CourseMenuItem";
import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { LiteProfile } from "@converge-collective/common/models/Profile";

export function CourseNav({
  isOpen,
  network,
  profile,
}: {
  isOpen: boolean;
  network: WithRef<Network>;
  profile: WithRef<LiteProfile>;
}) {
  const { assignedChallenges: assignedCourse } = useAssignedChallenges(
    network,
    profile
  );

  if (!network || !assignedCourse || isEmpty(assignedCourse)) {
    return (
      <Link href={coursesRoute(network?.slug || "")} legacyBehavior>
        <MenuItem icon={<School />}>Courses</MenuItem>
      </Link>
    );
  }

  return (
    <SubMenu
      defaultOpen
      icon={<School />}
      label="Courses"
      rootStyles={{
        ["." + menuClasses.subMenuContent]: {
          inset: "92px auto auto 0 !important",
          ...(!isOpen && {
            overflow: "scroll",
            maxHeight: "700px",
            overflowX: "hidden",
          }),
        },
      }}
    >
      {assignedCourse?.map((course) => {
        return (
          <CourseMenuItem
            key={course.id}
            course={course}
            network={network}
            profile={profile}
          />
        );
      })}

      <Link href={coursesRoute(network?.slug || "")} legacyBehavior>
        <MenuItem icon={<School color="primary" />}>All Courses</MenuItem>
      </Link>
    </SubMenu>
  );
}
