import { AccountBox } from "@mui/icons-material";
import {
  AlertTitle,
  AppBar,
  Button,
  Link as MuiLink,
  Skeleton,
  Toolbar,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  getAuth,
  isSignInWithEmailLink,
  sendEmailVerification,
} from "firebase/auth";
import { PerformanceTrace, getPerformance, trace } from "firebase/performance";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { memo, useEffect, useState } from "react";
import { useAuth, useSigninCheck } from "reactfire";
import { useIsMobile } from "~/hooks/mobile";
import { useGlobalState } from "~/lib/globalHeaderState";
import { profileHasName, useLoggedInState } from "~/lib/useLoggedInState";
import { newsFeedRoute, onboardingRoutes } from "~/routes";
import { HeaderSlot } from "./HeaderSlot";
import { LoggedOutNav } from "./LoggedOutNav";
import { MarketingNav } from "./MarketingNav";
import { NetworkSponsor } from "./NetworkSponsor";
import BrandLogo from "./common/BrandLogo";

// dynamically import LoggedInNav because it includes many other deps like
// creating an event which brings google maps places and the rich text editor
const DynamicLoggedInNav = dynamic(() => import("./LoggedInNav"), {
  ssr: false,
});

export const Header = memo(function Header(): React.ReactElement {
  const [loginTrace, setLoginTrace] = useState<PerformanceTrace>();

  const auth = useAuth();
  useEffect(() => {
    // must be initialized client side only
    const perf = getPerformance();
    // only set loginTrace once
    console.log("Header set loginTrace", { loginTrace });
    if (!loginTrace) {
      const t = trace(perf, "AUTH_USER_LOADED");
      setLoginTrace(t);
      t.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = useRouter();
  const { status, data: signInCheckResult } = useSigninCheck();
  const { asPath, query: urlQuery } = router;

  const isInviteToCreateAccount = "ca" in urlQuery;

  const isHarvest = router.asPath === "/harvest-church";
  // console.log("Header", { isHarvest, pathname: router.asPath });

  useEffect(() => {
    const onRouteChangeStart = () => {
      // disabled to see if it improves performance
      // clearFirestoreCache();
    };
    router.events.on("routeChangeStart", onRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [router.events]);
  const [, setCreateAccountVisible] = useGlobalState(
    "isCreateAccountOverlayOpen"
  );
  const [, setSignInVisible] = useGlobalState("isSignInOverlayOpen");

  // allow query param to open create account overlay
  useEffect(() => {
    console.log("Header query params", { urlQuery });
    if (isInviteToCreateAccount) {
      setCreateAccountVisible(true);
    }
  }, [urlQuery, setCreateAccountVisible, isInviteToCreateAccount]);

  const { currentUser: user } = auth;
  const isUnverified = user && !user.emailVerified;
  console.log("Header", { isUnverified, user });

  const { network, profile } = useLoggedInState();
  console.log("Header network", { network });

  // we only show network-wide sponsors on the newsfeed
  const isFeed = network && asPath == newsFeedRoute(network.slug);

  useEffect(() => {
    // user is loaded, so stop the trace
    if (user && loginTrace) {
      console.log("Header stop trace loginTrace", { loginTrace });
      loginTrace.stop();
      // prevent this block from firing again
      setLoginTrace(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, loginTrace]);

  const isMobile = useIsMobile();

  const marketingPaths = [
    ...["/about", "/contact", "/billings", "/billings/sponsorship"],
    ...(user ? [] : ["/billings/events", "/billings/groups"]),
  ];
  const isPublic = [
    "/create-network",
    "/privacy-policy",
    "/terms",
    "/security",
  ].includes(asPath);
  const isMarketing = marketingPaths.find((path) => asPath.startsWith(path));

  const networkSlug = "billings";
  // const marketingNav = marketingNavItems(networkSlug, undefined);
  // const hideMainHeaderNav = false;

  const [resendDisabled, setResendDisabled] = useState(false);

  const resendVerification = async () => {
    if (!user) {
      console.error("No user to resend verification email to");
      return;
    }
    await sendEmailVerification(user);
    setResendDisabled(true);
  };

  const userNavIsLoading =
    status === "loading" || (!!signInCheckResult?.signedIn && !user);

  console.log("userNavIsLoading", { userNavIsLoading });

  useEffect(
    function showSignIn() {
      // check if the user is signing in with a magic link
      const auth = getAuth();
      const email = urlQuery.email;
      const isSigningInWithEmail =
        email &&
        typeof email === "string" &&
        isSignInWithEmailLink(auth, window.location.href);

      // after load, if we still don't have a signed in user automatically show
      // the sign in dialog
      if (
        !isSigningInWithEmail &&
        !isMarketing &&
        !isPublic &&
        !isInviteToCreateAccount &&
        status !== "loading" &&
        !user?.uid
      ) {
        // show the dialog
        console.log("show sign in dialog");
        setSignInVisible(true);
      }
    },
    [
      urlQuery.email,
      status,
      setSignInVisible,
      user?.uid,
      isInviteToCreateAccount,
      isMarketing,
    ]
  );

  const needsProfileName = profile && !profileHasName(profile);
  // we can remove this if we end up not wanting to use it
  // const needsProfilePhoto = profile && !profileHasPhoto(profile);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          m: 0,
          p: 0,
          background: "white",
          // use custom box shadow instead of elevation={1} so we can get shadow
          // only on the bottom. use this generator to tweak it:
          // https://htmlcssfreebies.com/box-shadow-bottom/
          boxShadow: "0 6px 17px -17px rgba(0,0,0,0.45)",
        }}
      >
        <Toolbar sx={{ px: 2 }}>
          {/* in mobile or logged-out view render the logo since there won't be
            a sidebar */}
          {(!user || isMobile) && (
            <BrandLogo
              height={38}
              width={40}
              sx={{ marginRight: "10px", marginTop: "5px" }}
            />
          )}
          {userNavIsLoading ? (
            <>
              <div style={{ marginLeft: "auto" }} />
              <Skeleton
                suppressHydrationWarning
                variant="circular"
                width={40}
                height={40}
              />
            </>
          ) : isMarketing && !user ? (
            <MarketingNav
              showLeftNav={true}
              showAuthNav={true}
              networkSlug={networkSlug}
              setSignInVisible={setSignInVisible}
              setCreateAccountVisible={setCreateAccountVisible}
            />
          ) : user ? (
            <DynamicLoggedInNav />
          ) : isHarvest ? null : (
            <LoggedOutNav
              setSignInVisible={setSignInVisible}
              setCreateAccountVisible={setCreateAccountVisible}
            />
          )}
        </Toolbar>

        {/*  disable the verify banner since people are having trouble verifying */}
        {isUnverified && false && (
          <Alert severity="info">
            <b>Thank you for signing up!</b> Please verify your email address.{" "}
            {resendDisabled ? (
              <>✓ Sent!</>
            ) : (
              <MuiLink
                component="button"
                disabled={resendDisabled}
                onClick={resendVerification}
              >
                Resend email.
              </MuiLink>
            )}
          </Alert>
        )}
        {/* allow sub pages to render content into the header */}
        <HeaderSlot />
      </AppBar>
      {needsProfileName ? (
        <Alert
          severity="warning"
          action={
            <Button
              onClick={() => router.push(onboardingRoutes.account)}
              color="warning"
              sx={{ whiteSpace: "nowrap" }}
              startIcon={<AccountBox />}
            >
              My Profile
            </Button>
          }
        >
          <AlertTitle sx={{ pt: 0.2 }}>
            {needsProfileName ? (
              <>Please finish setting up your profile</>
            ) : null}
          </AlertTitle>
        </Alert>
      ) : null}
      {/* Render network sponsors only on the newsfeed */}
      {network && isFeed && <NetworkSponsor network={network} />}
    </>
  );
});
