import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import { doc, setDoc } from "@firebase/firestore";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid2,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { ComponentType, ReactNode, useEffect, useState } from "react";
import { useAuth, useFirestore } from "reactfire";
import { converters } from "~/lib/converter";
import { errorMessage } from "~/lib/firebase-errors";
import { joinNetwork } from "~/lib/network";
import SocialSignIn from "./SocialSignIn";
import { useGlobalState } from "~/lib/globalHeaderState";

export function CreateAccount({
  handleClose,
  WrapperContent = Box,
  WrapperActions = Box,
  disableOnboardingRedirect = false,
}: {
  handleClose?: () => void;
  WrapperContent?: ComponentType<{ children: ReactNode }>;
  WrapperActions?: ComponentType<{ children: ReactNode }>;
  disableOnboardingRedirect?: boolean;
}) {
  const auth = useAuth();
  const firestore = useFirestore();
  const router = useRouter();
  const { query: urlQuery } = router;

  const [signInVisible, setSignInVisible] = useGlobalState(
    "isSignInOverlayOpen"
  );
  const [state, setState] = useState({
    email: urlQuery.e ? urlQuery.e.toString() : "",
    password: "",
    firstName: urlQuery.fn ? urlQuery.fn.toString() : "",
    lastName: urlQuery.ln ? urlQuery.ln.toString() : "",
    emailError: "",
    serverError: "",
    loading: false,
  });

  // if a user joins from /billings, auto join the billings network
  const isBillingsSignup = router.asPath.startsWith("/billings");
  // console.log(
  //   `CreateAccount isBillingsSignup: ${isBillingsSignup}`,
  //   router.asPath
  // );
  const fetchBillingsNetwork = async (): Promise<
    WithRef<Network> | undefined
  > => {
    try {
      const results = await getDocs(
        query(
          collectionGroup(firestore, "networks").withConverter(
            converters.network.read
          ),
          where("public", "==", true),
          where("slug", "==", "billings")
        ).withConverter(converters.network.read)
      );
      const billings =
        results.docs.length > 0 ? results.docs[0]?.data() : undefined;
      return billings;
    } catch (e) {
      console.error("error fetching billings network", e);
      return;
    }
  };
  const joinBillingsNetwork = async (profile: WithRef<Profile>) => {
    // auto join the billings network
    try {
      const billingsNetwork = await fetchBillingsNetwork();
      if (billingsNetwork) {
        joinNetwork(billingsNetwork, profile);
      } else {
        console.error("unable to find the billings network", billingsNetwork);
      }
    } catch (e) {
      console.error("error joining billings network", e);
    }
  };

  // populate state from url query params
  useEffect(() => {
    console.log("CreateAccount useEffect", {
      urlQuery,
      firstName: urlQuery.fn ? urlQuery.fn.toString() : "",
    });
    setState((s) => ({
      ...s,
      email: urlQuery.e ? urlQuery.e.toString() : "",
      firstName: urlQuery.fn ? urlQuery.fn.toString() : "",
      lastName: urlQuery.ln ? urlQuery.ln.toString() : "",
    }));
  }, [urlQuery]);

  const { loading } = state;

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const setters = {
    email: setEmailError,
    password: setPasswordError,
    firstName: setFirstNameError,
    lastName: setLastNameError,
  };

  const getValidation = (type: string, value: string) => {
    switch (type) {
      case "email":
        if (!value.length) return "Email is required";
        if (
          // eslint-disable-next-line no-useless-escape
          !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          return "Email is not valid";
        return "";
      case "password":
        if (value.length === 0) return "Password is required";
        if (value.length < 6)
          return "Password must be at least 6 characters long";
        return "";
      case "firstName":
        return isEmpty(value) ? "First name is required" : "";
      case "lastName":
        return isEmpty(value) ? "Last name is required" : "";
      default:
        return "";
    }
  };

  const validateInput = (key: string, value: string) => {
    const error = getValidation(key, value);
    console.log("validateInput", key, value, error);
    setters[key] && setters[key](error);
    return error;
  };

  const isFormValid = () => {
    const { firstName, lastName, email, password } = state;

    const _emailError = validateInput("email", email);
    const emailValid = isEmpty(_emailError);

    const _pwdError = validateInput("password", password);
    const pwdValid = isEmpty(_pwdError);

    const _firstNameError = validateInput("firstName", firstName);
    const firstNameValid = isEmpty(_firstNameError);

    const _lastNameError = validateInput("lastName", lastName);
    const lastNameValid = isEmpty(_lastNameError);

    return emailValid && pwdValid && firstNameValid && lastNameValid;
  };

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  const handleError = (error: FirebaseError) => {
    console.warn("error creating account", { error });
    const serverError = errorMessage(error);
    if (error.code.includes("email")) {
      setEmailError(serverError);
      setState({ ...state, loading: false });
    } else if (error.code.includes("password")) {
      setPasswordError(serverError);
      setState({ ...state, loading: false });
    } else {
      setState({ ...state, serverError, loading: false });
    }
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e && e.preventDefault();
    const { firstName, lastName, email, password } = state;

    if (isFormValid()) {
      setState((s) => ({ ...s, loading: true, serverError: "" }));
      try {
        await createUserWithEmailAndPassword(auth, email, password).then(
          async (userCredential) => {
            const user = userCredential.user;
            if (user) {
              // create their profile
              const profileRef = doc(
                firestore,
                "profiles",
                user.uid
              ).withConverter(converters.profile.write);
              console.log("creating profile for user", { user });
              const profile: WithRef<Profile> = {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                termsAccepted: false,
                createdAt: new Date(),
                id: user.uid,
                ref: profileRef,
                firstName,
                lastName,
                name: `${firstName || ""} ${lastName || ""}`,
              };

              await setDoc(profileRef, profile, { merge: true });
              if (isBillingsSignup) {
                console.log("auto joining billings network...", { profile });
                await joinBillingsNetwork(profile);
              }
              // await sendEmailVerification(user);
              setState((s) => ({ ...s, loading: false }));
              if (!disableOnboardingRedirect) {
                router.push("/onboarding/account");
              }
            } else {
              console.error("expected valid user after login");
            }
          }
        );
        handleClose?.();
      } catch (err) {
        handleError(err);
      } finally {
        setState((s) => ({ ...s, loading: false }));
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSubmit();
  };

  const { serverError } = state;

  if (auth.currentUser) {
    return (
      <Box>
        <Typography>Your account has been created</Typography>
      </Box>
    );
  }

  return (
    <>
      <WrapperContent>
        <Grid2 container alignItems="center" justifyContent="center">
          <Grid2
            size={{
              md: "grow",
            }}
          >
            <SocialSignIn label="Sign up" />
          </Grid2>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem>
            <span style={{ color: "#999" }}>or</span>
          </Divider>

          <Grid2
            size={{
              md: 8,
            }}
          >
            <Grid2 container columnSpacing={2} rowSpacing={0}>
              <Grid2
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <TextField
                  onKeyUp={handleKeyPress}
                  value={state.firstName}
                  error={firstNameError.length > 0}
                  helperText={firstNameError}
                  margin="normal"
                  required
                  autoFocus
                  id="firstName"
                  label="First Name"
                  type="firstName"
                  fullWidth
                  onChange={handleChange("firstName")}
                />
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <TextField
                  onKeyUp={handleKeyPress}
                  value={state.lastName}
                  error={lastNameError.length > 0}
                  helperText={lastNameError}
                  margin="normal"
                  required
                  id="lastName"
                  label="Last Name"
                  type="firstName"
                  fullWidth
                  onChange={handleChange("lastName")}
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  onKeyUp={handleKeyPress}
                  value={state.email}
                  error={emailError.length > 0}
                  helperText={emailError}
                  margin="normal"
                  required
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={handleChange("email")}
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  onKeyUp={handleKeyPress}
                  value={state.password}
                  error={passwordError.length > 0}
                  helperText={passwordError}
                  margin="normal"
                  required
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="Password"
                  fullWidth
                  onChange={handleChange("password")}
                />
              </Grid2>
            </Grid2>
            {serverError.length > 0 && (
              <Alert sx={{ mt: 2 }} severity="error">
                {serverError}
              </Alert>
            )}
          </Grid2>
        </Grid2>
      </WrapperContent>
      <WrapperActions>
        {handleClose && (
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        )}
        <Stack direction="column">
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
          >
            Create Account
          </LoadingButton>
          <Typography>
            Already have an account?
            <Button onClick={() => setSignInVisible(true)}>Sign in</Button>
          </Typography>
        </Stack>
      </WrapperActions>
    </>
  );
}
