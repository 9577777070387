import { WithRef } from "@converge-collective/common/models/Base";
import { Group } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import { Lock } from "@mui/icons-material";
import { Box, Chip, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { MenuItem } from "react-pro-sidebar";
import { groupDetailRoute } from "~/routes";

export function GroupMenuItem({
  group,
  network,
  unreadCount,
}: {
  group: WithRef<Group>;
  network: WithRef<Network>;
  unreadCount: number;
}) {
  const router = useRouter();
  const groupIconStyle: SxProps = group.photoUrl
    ? {
        backgroundImage: `url("${group.photoUrl}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }
    : { backgroundColor: group.color };

  const privateIcon = (
    <Tooltip title="Private Group">
      <Lock sx={{ color: "white" }} />
    </Tooltip>
  );

  const icon = group.isPrivate ? privateIcon : <></>;
  const isActive = (url: string) => router.asPath === url;
  const groupUrl = groupDetailRoute(network.slug, group.slug);

  return (
    <Box
      key={group.id}
      sx={{
        "& .ps-menu-icon": {
          ...groupIconStyle,
          borderRadius: 1,
        },
      }}
    >
      <MenuItem
        icon={icon}
        active={isActive(groupUrl)}
        onClick={() => router.push(groupUrl)}
        key={group.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        suffix={
          unreadCount > 0 ? (
            <Chip label={unreadCount} size="small" color="primary" />
          ) : null
        }
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip title={group.name}>
            <Typography
              sx={{
                fontWeight: unreadCount > 0 ? "bold" : "normal",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "90%",
              }}
              flex={1}
              display={"inline-block"}
            >
              {group.name}
            </Typography>
          </Tooltip>
        </Stack>
      </MenuItem>
    </Box>
  );
}
