import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { isNetworkAdmin } from "@converge-collective/common/models/NetworkAdmin";
import { Profile } from "@converge-collective/common/models/Profile";
import { CustomRole } from "@converge-collective/common/models/rbac/CustomRole";
import { NetworkPermissionSet } from "@converge-collective/common/models/rbac/NetworkPermission";
import { UserClaims } from "@converge-collective/common/models/User";
import { User } from "firebase/auth";
import { updateDoc } from "firebase/firestore";
import { isEmpty } from "lodash";
import { create } from "zustand";

export function profileHasName(profile: Profile) {
  return !isEmpty(profile.firstName);
}

export function profileHasPhoto(profile: Profile) {
  return !isEmpty(profile.photoURL);
}

export type LoggedInState = {
  user?: User;
  setUser: (user?: User) => void;

  profile?: WithRef<Profile>;
  setProfile: (profile?: WithRef<Profile>) => void;
  updateFirestoreProfile: (profile: Partial<Profile>) => void;

  network?: WithRef<Network>;
  setNetwork: (network?: WithRef<Network>) => void;

  permissions?: NetworkPermissionSet;
  setPermissions: (permissions?: NetworkPermissionSet) => void;

  roles?: WithRef<CustomRole>[];
  setRoles: (roles?: WithRef<CustomRole>[]) => void;

  userClaims?: UserClaims;
  setUserClaims: (userClaims?: UserClaims) => void;

  /** undefined means not yet loaded */
  isNetworkAdmin?: boolean;
  setIsNetworkAdmin: (isNetworkAdmin: boolean) => void;
};

/**
 * Store logged in state in a single store in order to avoid fetching it
 * multiple times in different components.
 *
 * The Auth component sets the state when the user logs in, and clears it when
 * the user logs out.
 */
export const useLoggedInState = create<LoggedInState>()((set, get) => ({
  // we can probably get rid of this. we used claims to determin network admin
  // up until 2025-02-14 but we don't use claims for anything else.
  userClaims: undefined,
  setUserClaims: (userClaims?: UserClaims) => {
    set({ userClaims });
  },

  /** default to undefined, will be updated once claims are checked */
  isNetworkAdmin: undefined,
  setIsNetworkAdmin: (isNetworkAdmin: boolean) => set({ isNetworkAdmin }),

  networkPermissions: undefined,
  setPermissions: (permissions?: NetworkPermissionSet) => set({ permissions }),

  roles: undefined,
  setRoles: (roles?: WithRef<CustomRole>[]) => set({ roles }),

  user: undefined,
  profile: undefined,
  network: undefined,
  setUser: (user?: User) => set({ user }),
  setProfile: (profile?: WithRef<Profile>) => set({ profile }),
  setNetwork: (network?: WithRef<Network>) => set({ network }),
  /**
   * Provide a convenience profile updater. This makes it easier to keep track
   * of all profile update usages with the type system as opposed to using the
   * raw firestre `updateDoc` throughout the app
   *
   * Unlike the above operations which only interact with memory, this writes
   * to Firestore.
   */
  updateFirestoreProfile: (partialProfile: Partial<Profile>) => {
    const profile = get().profile;
    if (profile) {
      updateDoc(profile.ref, partialProfile);
    }
  },
}));
