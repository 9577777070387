import { create } from "zustand";

export type CourseEditMode = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

export const useCourseEditMode = create<CourseEditMode>((set) => ({
  isEditing: false,
  setIsEditing: (isEditing) => set({ isEditing }),
}));
