import { WithRef } from "@converge-collective/common/models/Base";
import { ChallengeV2 } from "@converge-collective/common/models/ChallengeV2";
import { Network } from "@converge-collective/common/models/Network";
import { LiteProfile } from "@converge-collective/common/models/Profile";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { MenuItem } from "react-pro-sidebar";
import { useChallengeCompletion } from "~/hooks/useChallenges";
import { courseRoute } from "~/routes";

export function CourseMenuItem({
  course,
  network,
  profile,
}: {
  course: WithRef<ChallengeV2>;
  network: WithRef<Network>;
  profile: WithRef<LiteProfile>;
}) {
  const router = useRouter();
  const courseUrl = courseRoute(network.slug, course.slug);
  const { isCompleted } = useChallengeCompletion(course, profile);
  const icon = <></>;
  return (
    <>
      {!isCompleted && (
        <Box
          key={course.id}
          sx={{
            "& .ps-menu-icon": {
              ...(course.photoUrls[0]
                ? {
                    backgroundImage: `url(${course.photoUrls[0]})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }
                : { backgroundColor: course.backgroundColor }),
              borderRadius: 1,
            },
          }}
        >
          <MenuItem
            icon={icon}
            onClick={() => router.push(courseUrl)}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Tooltip title={course.name}>
                <Typography
                  sx={{
                    fontWeight: "normal",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "90%",
                  }}
                  flex={1}
                  display={"inline-block"}
                >
                  {course.name}
                </Typography>
              </Tooltip>
            </Stack>
          </MenuItem>
        </Box>
      )}
    </>
  );
}
