import { useGlobalState } from "~/lib/globalHeaderState";
import CreateAccountDialog from "./auth/CreateAccountDialog";
import { SignInDialog } from "./auth/SignInDialog";

export function AuthModals() {
  const [createAccountVisible, setCreateAccountVisible] = useGlobalState(
    "isCreateAccountOverlayOpen"
  );
  const [signInVisible, setSignInVisible] = useGlobalState(
    "isSignInOverlayOpen"
  );
  return (
    <>
      {/* DO NOT REMOVE: Recaptcha container - used by PhoneSignIn */}
      {/* TODO can we hide this recaptcha after login? */}
      <div id="recaptcha-container" />

      {/* Modals: */}
      {createAccountVisible && (
        <CreateAccountDialog
          isOpen={createAccountVisible}
          handleClose={() => setCreateAccountVisible(false)}
        />
      )}
      {signInVisible && (
        <SignInDialog
          isOpen={signInVisible}
          handleClose={() => setSignInVisible(false)}
        />
      )}
    </>
  );
}
