import { Email, Phone } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid2,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useState } from "react";
import { useAuth } from "reactfire";
import { PhoneSignIn } from "./PhoneSignIn";
import SocialSignIn from "./SocialSignIn";
import { FirebaseErrors } from "./firebaseErrorMessages";

export function SignInDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}): React.ReactElement {
  const auth = useAuth();
  const [emailOrPhone, setEmailOrPhone] = useState<"email" | "phone">("email");

  const [state, setState] = useState({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    serverError: "",
    message: "",
    submitted: false,
    forgottenPwd: false,
    loading: false,
  });

  const { email, password, serverError, message, forgottenPwd } = state;

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const setters = {
    email: setEmailError,
    password: setPasswordError,
  };

  const getValidation = (type: string, value: string) => {
    switch (type) {
      case "email":
        if (!value.length) return "Email is required";
        if (
          // eslint-disable-next-line no-useless-escape
          !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          return "Email is not valid";
        return "";
      case "password":
        if (value.length === 0) return "Password is required";
        if (value.length < 6)
          return "Password must be at least 6 characters long";
        return "";
      default:
        return "";
    }
  };

  const validateInput = (key: string, value: string) => {
    const error = getValidation(key, value);
    setters[key] && setters[key](error);
    return error;
  };

  const isFormValid = () => {
    const { forgottenPwd, email, password } = state;

    const emailError = validateInput("email", email);
    const emailValid = emailError.length === 0;

    if (forgottenPwd) return emailValid;

    const pwdError = validateInput("password", password);
    const pwdValid = pwdError.length === 0;

    return emailValid && pwdValid;
  };

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      // console.log(event.key);
      setState({ ...state, [prop]: event.target.value });
    };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSubmit();
  };

  const handleError = (error: { code: string; message: string }) => {
    const errorMessage =
      error.code && FirebaseErrors[error.code]
        ? FirebaseErrors[error.code]
        : "Something went wrong. Please try again or contact support.";

    setState({ ...state, serverError: errorMessage, loading: false });
    console.warn("error submitting", error);
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e && e.preventDefault();
    if (isFormValid()) {
      const nextState = {
        ...state,
        loading: true,
        submitted: true,
        serverError: "",
        message: "",
      };

      try {
        if (forgottenPwd) {
          sendPasswordResetEmail(auth, email);
          setState({
            ...nextState,
            forgottenPwd: false,
            message: "We've sent password reset link to your inbox",
          });
        } else {
          setState(nextState);
          await signInWithEmailAndPassword(auth, email, password);
          // if auth failed it will throw an exception and get caught in the
          // below `catch`. if it succeeded, close the dialog.
          handleClose();
        }
      } catch (err) {
        console.error("error logging in", { err });
        setState(nextState);
        handleError(err);
      }
    } else {
      console.warn("Form is invalid");
    }
  };

  const toggleEmailOrPhone = () =>
    setEmailOrPhone(emailOrPhone === "email" ? "phone" : "email");

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {!forgottenPwd ? "Sign in to Converge" : "Forgot password"}
      </DialogTitle>
      <DialogContent>
        <Grid2 container alignItems="center" justifyContent="center">
          {!forgottenPwd && (
            <>
              <Grid2
                size={{
                  md: "grow",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  {emailOrPhone === "email" ? (
                    <Button
                      onClick={toggleEmailOrPhone}
                      variant="contained"
                      fullWidth
                      startIcon={<Phone />}
                    >
                      Login with Phone Number
                    </Button>
                  ) : (
                    <Button
                      onClick={toggleEmailOrPhone}
                      variant="contained"
                      fullWidth
                      startIcon={<Email />}
                    >
                      Login with Email
                    </Button>
                  )}
                </Box>
                <SocialSignIn />
              </Grid2>
              <Divider sx={{ mx: 1 }} orientation="vertical" flexItem>
                <span style={{ color: "#999" }}>or</span>
              </Divider>
            </>
          )}
          <Grid2
            size={{
              md: "grow",
            }}
          >
            <Collapse in={emailOrPhone === "email"}>
              <form onSubmit={handleSubmit}>
                <DialogContentText></DialogContentText>

                <TextField
                  onKeyUp={handleKeyUp}
                  error={emailError.length > 0}
                  helperText={emailError}
                  margin="normal"
                  required
                  autoFocus
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={handleChange("email")}
                />
                {forgottenPwd && (
                  <Typography variant="body1">
                    Enter your email and we'll send you a link to reset your
                    password.
                  </Typography>
                )}
                {!forgottenPwd && (
                  <>
                    <TextField
                      onKeyUp={handleKeyUp}
                      error={passwordError.length > 0}
                      helperText={passwordError}
                      margin="normal"
                      required
                      id="password"
                      label="Password"
                      type="password"
                      autoComplete="Password"
                      fullWidth
                      onChange={handleChange("password")}
                    />
                  </>
                )}

                {serverError.length > 0 && (
                  <Alert severity="error">{serverError}</Alert>
                )}
                {message.length > 0 && (
                  <FormHelperText>{message}</FormHelperText>
                )}
              </form>
            </Collapse>
            <Collapse in={emailOrPhone === "phone"}>
              <PhoneSignIn />
            </Collapse>
          </Grid2>
        </Grid2>
      </DialogContent>
      <Collapse in={emailOrPhone === "email"}>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Stack direction="row" spacing={1}>
            <Button
              onClick={() =>
                setState({
                  ...state,
                  forgottenPwd: !forgottenPwd,
                  serverError: "",
                  message: "",
                })
              }
            >
              {!forgottenPwd ? "Forgot password?" : "Sign in"}
            </Button>

            <div className="fxFlex" />

            <Button onClick={handleSubmit} color="primary" variant="contained">
              {!forgottenPwd ? "Sign in" : "Send password reset"}
            </Button>
          </Stack>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
}
