import { Sms } from "@mui/icons-material";
import { Alert, Button, Stack, TextField } from "@mui/material";
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import parsePhoneNumber, { AsYouType, PhoneNumber } from "libphonenumber-js";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useGlobalState } from "~/lib/globalHeaderState";
import { FirebaseErrors } from "./firebaseErrorMessages";

declare const window: {
  recaptchaVerifier?: RecaptchaVerifier;
  recaptchaWidgetId?: number;
  confirmationResult?: ConfirmationResult;
};

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier;
  }
}

export function PhoneSignIn(): React.ReactElement {
  const asYouType = new AsYouType("US");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [validParsedPhoneNumber, setValidParsedPhoneNumber] =
    useState<PhoneNumber>();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isVerificationSent, setIsVerificationSent] = useState(false);

  const [, setSignInVisible] = useGlobalState("isSignInOverlayOpen");

  useEffect(() => {
    const auth = getAuth();
    console.log("PhoneSignIn", { auth });
    // Ensure recaptchaVerifier is not created multiple times
    if (!window.recaptchaVerifier) {
      // Initialize reCAPTCHA, passing auth as the first parameter
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth, // Pass auth as the first argument
        "recaptcha-container",
        {
          size: "invisible", // 'normal' for a visible reCAPTCHA widget
          callback: (response: any) => {
            // reCAPTCHA solved
            console.log("reCAPTCHA solved", response);
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
          },
        }
      );

      window.recaptchaVerifier.render().catch((err: any) => {
        console.error("Error rendering reCAPTCHA", err);
      });
    }

    return () => {
      console.log("unmounting");
      // window.recaptchaVerifier?.clear();
      // window.recaptchaVerifier = undefined;
    };
  }, []);

  const sendVerificationCode = async () => {
    const auth = getAuth(); // Use getAuth without arguments
    if (!validParsedPhoneNumber) {
      setError("Invalid phone number");
      return;
    }
    const validPhoneNumber = validParsedPhoneNumber.formatInternational();

    try {
      setError("");
      const appVerifier = window.recaptchaVerifier;
      if (!appVerifier) {
        console.error("missing recaptcha app verifier", { appVerifier });
        throw new Error("There was a problem verifying reCAPTCHA");
      }
      console.log("sendVerificationCode", { phoneNumber, appVerifier });
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        validPhoneNumber,
        appVerifier
      );
      setConfirmationResult(confirmationResult);
      setSuccess("Verification code sent!");
      setIsVerificationSent(true);
    } catch (err: any) {
      console.error("error sending verification code", { err });
      const code = err.code;
      const message =
        code && FirebaseErrors[code]
          ? `Error sending verification code: ${FirebaseErrors[code].toLowerCase()}`
          : "Error sending verification code. Please try again or contact support.";
      // error from firebase
      setError(message);
    }
  };

  const verifyCode = async () => {
    if (!confirmationResult) return;

    try {
      setError("");
      await confirmationResult.confirm(verificationCode);
      setSuccess("Phone number verified!");
      // user is logged in so close the dialog
      setSignInVisible(false);
    } catch (err: any) {
      setError("Invalid verification code");
    }
  };

  const validatePhoneNumber = (numberToParse: string) => {
    const parsed = parsePhoneNumber(numberToParse, "US");

    const isValid = parsed && parsed.isValid();
    const isPossible = parsed && parsed.isPossible();
    console.log("attempted parse", {
      parsePhoneNumber,
      numberToParse,
      parsed,
      isValid,
      isPossible,
      valid: parsed && parsed.isValid(),
    });

    // we'll relax constraints and only go with isPossible to let us use our
    // testing numbers like 555-555-5555
    if (!isPossible) {
      setIsValidPhoneNumber(false);
    } else {
      console.log("valid!");
      setIsValidPhoneNumber(true);
      setValidParsedPhoneNumber(parsed);
      return parsed.formatInternational();
    }
  };

  return (
    <Stack spacing={2}>
      {!isVerificationSent && (
        <Stack spacing={2}>
          {/* TODO add E164 validation */}
          <TextField
            value={phoneNumber}
            onChange={(e) => {
              const input = e.target.value.replace("-", "");
              const formattedNumber = asYouType.input(input);
              setPhoneNumber(formattedNumber);
              validatePhoneNumber(formattedNumber);
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                setPhoneNumber((prev) => prev.slice(0, -1));
              }
            }}
            onBlur={() => validatePhoneNumber(phoneNumber)}
            fullWidth
            autoComplete="tel"
            id="phone"
            label="Mobile Number"
            error={!isValidPhoneNumber}
            helperText={
              isValidPhoneNumber
                ? "We'll text you a verification code you can use to log in to your account."
                : "Please enter a valid phone number, e.g. 555 555 5555"
            }
          />

          <Button
            disabled={!validParsedPhoneNumber}
            startIcon={<Sms />}
            sx={{ mt: 2 }}
            id="phone-sign-in-button"
            variant="contained"
            onClick={sendVerificationCode}
          >
            Send verification code
          </Button>
        </Stack>
      )}

      {isVerificationSent && (
        <Stack spacing={2}>
          <TextField
            label="Verification Code"
            helperText="Please enter the verification code sent to your phone."
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            fullWidth
          />

          <Button
            sx={{ mt: 2 }}
            id="phone-verify-button"
            variant="contained"
            onClick={verifyCode}
          >
            Verify
          </Button>
        </Stack>
      )}

      {!isEmpty(error) && <Alert severity="error">{error}</Alert>}

      {!isEmpty(success) && <Alert severity="success">{success}</Alert>}
    </Stack>
  );
}
