import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useLoggedInState } from "~/lib/useLoggedInState";
import {
  NavItem,
  coursesRoute,
  docsRoute,
  groupsRoute,
  newsFeedRoute,
} from "~/routes";
import { SidebarNav } from "./SidebarNav";

const useStyles = makeStyles({
  drawerContainer: {},
  iconButtonContainer: {
    marginLeft: "auto",
    color: "black",
    paddingLeft: 0,
  },
  iconButton: {
    display: "flex",
    flexDirection: "column",
    "& svg": {
      fontSize: "34px",
    },
  },

  indicator: {
    top: "0px",
  },
});

export default function MobileNav({
  networkNav,
}: {
  networkNav: NavItem[];
}): React.ReactElement {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { network } = useLoggedInState();
  const router = useRouter();
  const MaxBottomNavItems = 4;

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      // when the route changes close the drawer
      setOpenDrawer(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** these routes are manually rendered in the desktop-only SidebarNav,
   * so add them to mobile nav here too
   */
  const firstRoutes: NavItem[] = network
    ? ([
        {
          url: newsFeedRoute(network.slug),
          label: "Feed",
          icon: dynamic(() => import("@mui/icons-material/Home")),
        },
        {
          url: groupsRoute(network.slug),
          label: "Groups",
          icon: dynamic(() => import("@mui/icons-material/Groups")),
        },
        ...(!network.settings?.areDocsDisabled
          ? [
              {
                url: docsRoute(network.slug),
                label: "Docs & Files",
                icon: dynamic(() => import("@mui/icons-material/Description")),
              },
            ]
          : []),
        ...(network.settings?.areCoursesEnabled
          ? [
              {
                url: coursesRoute(network.slug),
                label: "Courses",
                icon: dynamic(() => import("@mui/icons-material/School")),
              },
            ]
          : []),
      ] as NavItem[])
    : [];

  const allNavItems = [...firstRoutes, ...networkNav];
  const tabbedNav = allNavItems.slice(0, MaxBottomNavItems);

  const classes = useStyles();
  return (
    <>
      {/* bottom tab nav for mobile */}
      <Drawer anchor="bottom" open={true} variant="persistent">
        <Tabs
          variant="fullWidth"
          sx={{
            mb: 2,
            ".MuiTab-labelIcon": {
              textTransform: "none",
              whiteSpace: "nowrap",
              color: "#333",
              "& .MuiButtonBase": {},
              fontSize: 11,
              "& svg": {
                fontSize: "30px",
              },
            },
          }}
          classes={{
            indicator: classes.indicator,
          }}
          allowScrollButtonsMobile
          value={router.asPath}
        >
          {tabbedNav.map((navItem) => (
            <Tab
              value={navItem.url}
              sx={{ minWidth: 60 }}
              onClick={() => {
                navItem.onClick
                  ? navItem.onClick()
                  : navItem.url && router.push(navItem.url);
              }}
              icon={
                navItem.icon ? (
                  React.createElement(navItem.icon)
                ) : (
                  <>
                    <MenuIcon />
                  </>
                )
              }
              key={navItem.label}
              label={navItem.label}
            />
          ))}
          <Tab
            onClick={() => setOpenDrawer(true)}
            sx={{ minWidth: 30 }}
            icon={<MenuIcon color="secondary" fontSize="large" />}
          />
        </Tabs>
      </Drawer>

      {/* left side nav for mobile */}
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawerContainer }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        <SidebarNav allowMobile={true} showExpandCollapse={false} />
      </Drawer>
    </>
  );
}
